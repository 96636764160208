import React from 'react';
import Grid from '@material-ui/core/Grid';

import biopart from '../img/bioparts.jpeg';

export default function Bioplastics() {
  return (
    <>
            <Grid
                container
                direction="column"
                
                alignItems="center"
                className="respWrap"
            >
            <h1 className="siteSubHeadline">
            Spritzguss mit kompostierbaren Kunststoffen aus erneuerbaren Rohstoffen
          </h1>
          <span className="pitchText">Sie möchten Ihren Spritzgussartikel auf einen regenerativen Rohstoff umstellen? Sprechen Sie uns an!</span>
          <span className="pitchText">Wir haben umfangreiche Erfahrung mit der Verarbeitung von abbaubaren Kunsttoffen wie PHA, PHB, PLA. </span>
           <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                style={{height: "60vh"}}
            >
                <Grid 
                    item
                    xs={11}
                    sm={5}
                    className="cardItem-lg"
                    style={{paddingBottom:"0px",paddingLeft:"5px",paddingRight:"5px"}}
                >
                    
                    
                    
                        <img className="processPic" src={biopart} alt="Kunststoffbehälter aus PHA" />
                </Grid>
                <Grid 
                    item
                    xs={11}
                    sm={5}
                    className="cardItem-lg"
                    style={{textAlign: "center",paddingLeft:"10px"}}
                >

                    <p><span className="expText"><strong>Industriell und sogar heimkompostierbar</strong></span></p>
                    <p><span className="expText">Wir arbeiten mit allen gängigen Arten von Biokunststoff und können Sie individuell beraten</span></p>
                  
                    <p><span className="expText"><strong>Bis zu 100% aus nachwachsenden Rohstoffen</strong></span></p>
                    <p><span className="expText">PHA oder PLA werden beispielsweise aus biogenen Abfallstoffen oder Zuckerrohr gewonnen</span></p>
                   
                    <p><span className="expText"><strong>Beständig und Robust auch ohne Erdöl</strong></span></p>
                    <p><span className="expText">Wir arbeiten mit einem breiten Spektrum an Herstellern und können so auch komplexe technische Anforderungen in die Fertigung überführen</span></p>
                 

                </Grid>

            
                
            </Grid>

            
            </Grid>
    </>
  );
}