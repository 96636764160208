import React from 'react';
import Grid from '@material-ui/core/Grid';

export default function DataProtection(props) {
    return (
      <Grid
                container
                direction="column"
                alignItems="center"
                className="respWrap"
                
                
            >

      <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                style={{height: "100vh",overflow:"scroll",padding:"10px",display:"flex"}}
                
                
                
            >
        
  
       
        <article className="PrivacyPolicy">
          <main className="PrivacyPolicy-Main container">
            <h1 style={{color:"white"}}>Datenschutzerklärung</h1>
            <h2 style={{color:"white"}}>
              1. Grundsätzliche Angaben zur Datenverarbeitung und Rechtsgrundlagen
            </h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                1.1. Diese Datenschutzerklärung klärt Sie über die Art, den Umfang
                und Zweck der Verarbeitung von personenbezogenen Daten innerhalb
                unseres Onlineangebotes und der mit ihm verbundenen Webseiten,
                Funktionen und Inhalte (nachfolgend gemeinsam bezeichnet als
                „Onlineangebot“ oder „Website“) auf. Die Datenschutzerklärung gilt
                unabhängig von den verwendeten Domains, Systemen, Plattformen und
                Geräten (z.B. Desktop oder Mobile) auf denen das Onlineangebot
                ausgeführt wird.
              </li>
              <li className="PrivacyPolicy-item">
                1.2. Die verwendeten Begrifflichkeiten, wie z.B. „personenbezogene
                Daten“ oder deren „Verarbeitung“ verweisen wir auf die
                Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
              </li>
              <li className="PrivacyPolicy-item">
                1.3. Zu den im Rahmen dieses Onlineangebotes verarbeiteten
                personenbezogenen Daten der Nutzer gehören Bestandsdaten (z.B.,
                Namen und Adressen von Kunden), Vertragsdaten (z.B., in Anspruch
                genommene Leistungen, Namen von Sachbearbeitern,
                Zahlungsinformationen), Nutzungsdaten (z.B., die besuchten
                Webseiten unseres Onlineangebotes, Interesse an unseren Produkten)
                und Inhaltsdaten (z.B., Eingaben im Kontaktformular).
              </li>
              <li className="PrivacyPolicy-item">
                1.4. Der Begriff „Nutzer“ umfasst alle Kategorien von der
                Datenverarbeitung betroffener Personen. Zu ihnen gehören unsere
                Geschäftspartner, Kunden, Interessenten und sonstige Besucher
                unseres Onlineangebotes. Die verwendeten Begrifflichkeiten, wie
                z.B. „Nutzer“ sind geschlechtsneutral zu verstehen.
              </li>
              <li className="PrivacyPolicy-item">
                1.5. Wir verarbeiten personenbezogene Daten der Nutzer nur unter
                Einhaltung der einschlägigen Datenschutzbestimmungen. Das
                bedeutet, die Daten der Nutzer werden nur bei Vorliegen einer
                gesetzlichen Erlaubnis verarbeitet. D.h., insbesondere wenn die
                Datenverarbeitung zur Erbringung unserer vertraglichen Leistungen
                (z.B. Bearbeitung von Aufträgen) sowie Online-Services
                erforderlich, bzw. gesetzlich vorgeschrieben ist, eine
                Einwilligung der Nutzer vorliegt, als auch aufgrund unserer
                berechtigten Interessen (d.h. Interesse an der Analyse,
                Optimierung und wirtschaftlichem Betrieb und Sicherheit unseres
                Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO,
                insbesondere bei der Reichweitenmessung, Erstellung von Profilen
                zu Werbe- und Marketingzwecken sowie Erhebung von Zugriffsdaten
                und Einsatz der Dienste von Drittanbietern.
              </li>
              <li className="PrivacyPolicy-item">
                1.6. Wir weisen darauf hin, dass die Rechtsgrundlage der
                Einwilligungen Art. 6 Abs. 1 lit. a. und Art. 7 DSGVO, die
                Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
                Leistungen und Durchführung vertraglicher Maßnahmen Art. 6 Abs. 1
                lit. b. DSGVO, die Rechtsgrundlage für die Verarbeitung zur
                Erfüllung unserer rechtlichen Verpflichtungen Art. 6 Abs. 1 lit.
                c. DSGVO, und die Rechtsgrundlage für die Verarbeitung zur Wahrung
                unserer berechtigten Interessen Art. 6 Abs. 1 lit. f. DSGVO ist.
              </li>
            </ul>
            <h2>2. Sicherheitsmaßnahmen</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                2.1. Wir treffen organisatorische, vertragliche und technische
                Sicherheitsmaßnahmen entsprechend dem Stand der Technik, um
                sicherzustellen, dass die Vorschriften der Datenschutzgesetze
                eingehalten werden und um damit die durch uns verarbeiteten Daten
                gegen zufällige oder vorsätzliche Manipulationen, Verlust,
                Zerstörung oder gegen den Zugriff unberechtigter Personen zu
                schützen.
              </li>
              <li className="PrivacyPolicy-item">
                2.2. Zu den Sicherheitsmaßnahmen gehört insbesondere die
                verschlüsselte Übertragung von Daten zwischen Ihrem Browser und
                unserem Server.
              </li>
            </ul>
            <h2>3. Weitergabe von Daten an Dritte und Drittanbieter</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                3.1. Eine Weitergabe von Daten an Dritte erfolgt nur im Rahmen der
                gesetzlichen Vorgaben. Wir geben die Daten der Nutzer an Dritte
                nur dann weiter, wenn dies z.B. auf Grundlage des Art. 6 Abs. 1
                lit. b) DSGVO für Vertragszwecke erforderlich ist oder auf
                Grundlage berechtigter Interessen gem. Art. 6 Abs. 1 lit. f. DSGVO
                an wirtschaftlichem und effektivem Betrieb unseres
                Geschäftsbetriebes.
              </li>
              <li className="PrivacyPolicy-item">
                3.2. Im Rahmen der Angebotserstellung ist es erforderlich, dass
                wir Sie um Ihre Zustimmung bitten, Ihre Adressdaten und ggf.
                Rufnummer an den Anbieter - d.h. Hersteller oder Händler zu
                übermitteln. Die Zustimmung erfolgt auf der Seite zur
                Profilvervollständigung und ist Voraussetzung für die
                Angebotserstellung und Abwicklung. Für selbigen Zweck werden Ihre
                Daten ebenso EDV-technisch verarbeitet. Auf Wunsch löschen wir Sie
                gerne aus unseren Systemen, schreiben Sie uns dazu einfach eine
                Nachricht.
              </li>
              <li className="PrivacyPolicy-item">
                3.3. Sofern wir Subunternehmer einsetzen, um unsere Leistungen
                bereitzustellen, ergreifen wir geeignete rechtliche Vorkehrungen
                sowie entsprechende technische und organisatorische Maßnahmen, um
                für den Schutz der personenbezogenen Daten gemäß den einschlägigen
                gesetzlichen Vorschriften zu sorgen.
              </li>
              <li className="PrivacyPolicy-item">
                3.4. Sofern im Rahmen dieser Datenschutzerklärung Inhalte,
                Werkzeuge oder sonstige Mittel von anderen Anbietern (nachfolgend
                gemeinsam bezeichnet als „Drittanbieter“) eingesetzt werden und
                deren genannter Sitz sich in einem Drittland befindet, ist davon
                auszugehen, dass ein Datentransfer in die Sitzstaaten der
                Drittanbieter stattfindet. Als Drittstaaten sind Länder zu
                verstehen, in denen die DSGVO kein unmittelbar geltendes Recht
                ist, d.h. grundsätzlich Länder außerhalb der EU, bzw. des
                Europäischen Wirtschaftsraums. Die Übermittlung von Daten in
                Drittstaaten erfolgt entweder, wenn ein angemessenes
                Datenschutzniveau, eine Einwilligung der Nutzer oder sonst eine
                gesetzliche Erlaubnis vorliegt.
              </li>
            </ul>
            <h2>4. Erbringung vertraglicher Leistungen</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                4.1. Wir verarbeiten Bestandsdaten (z.B., Namen und Adressen sowie
                Kontaktdaten von Nutzern), Vertragsdaten (z.B., in Anspruch
                genommene Leistungen, Namen von Kontaktpersonen,
                Zahlungsinformationen) zwecks Erfüllung unserer vertraglichen
                Verpflichtungen und Serviceleistungen gem. Art. 6 Abs. 1 lit b.
                DSGVO.
              </li>
              <li className="PrivacyPolicy-item">
                4.2. Wir verarbeiten Nutzungsdaten (z.B., die besuchten Webseiten
                unseres Onlineangebotes, Interesse an unseren Produkten) und
                Inhaltsdaten (z.B., Eingaben im Kontaktformular oder Nutzerprofil)
                für Werbezwecke in einem Nutzerprofil, um den Nutzer z.B.
                Produkthinweise ausgehend von ihren bisher in Anspruch genommenen
                Leistungen einzublenden.
              </li>
            </ul>
            <h2>5. Kontaktaufnahme</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                5.1. Bei der Kontaktaufnahme mit uns (per Kontaktformular oder
                E-Mail) werden die Angaben des Nutzers zur Bearbeitung der
                Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b)
                DSGVO verarbeitet.
              </li>
              <li className="PrivacyPolicy-item">
                5.2. Die Angaben der Nutzer können in unserem
                Customer-Relationship-Management System ("CRM System") oder
                vergleichbarer Anfragenorganisation gespeichert werden.
              </li>
            </ul>
            <h2>6. Kommentare und Beiträge</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                6.1. Wenn Nutzer Kommentare oder sonstige Beiträge hinterlassen,
                werden ihre IP-Adressen auf Grundlage unserer berechtigten
                Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO für 7 Tage
                gespeichert.
              </li>
              <li className="PrivacyPolicy-item">
                6.2. Das erfolgt zu unserer Sicherheit, falls jemand in
                Kommentaren und Beiträgen widerrechtliche Inhalte hinterlässt
                (Beleidigungen, verbotene politische Propaganda, etc.). In diesem
                Fall können wir selbst für den Kommentar oder Beitrag belangt
                werden und sind daher an der Identität des Verfassers
                interessiert.
              </li>
            </ul>
            <h2>7. Erhebung von Zugriffsdaten und Logfiles</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                7.1. Wir erheben auf Grundlage unserer berechtigten Interessen im
                Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten über jeden Zugriff auf
                den Server, auf dem sich dieser Dienst befindet (sogenannte
                Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen
                Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene
                Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst
                Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
                besuchte Seite), IP-Adresse und der anfragende Provider.
              </li>
              <li className="PrivacyPolicy-item">
                7.2. Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur
                Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer
                von maximal sieben Tagen gespeichert und danach gelöscht. Daten,
                deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind
                bis zur endgültigen Klärung des jeweiligen Vorfalls von der
                Löschung ausgenommen.
              </li>
            </ul>
            <h2>8. Cookies &amp; Reichweitenmessung</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                8.1. Cookies sind Informationen, die von unserem Webserver oder
                Webservern Dritter an die Web-Browser der Nutzer übertragen und
                dort für einen späteren Abruf gespeichert werden. Bei Cookies kann
                es sich um kleine Dateien oder sonstige Arten der
                Informationsspeicherung handeln.
              </li>
              <li className="PrivacyPolicy-item">
                8.2. Wir verwenden „Session-Cookies“, die nur für die Zeitdauer
                des aktuellen Besuchs auf unserer Onlinepräsenz abgelegt werden
                (z.B. um die Speicherung Ihres Login-Status oder die
                Warenkorbfunktion und somit die Nutzung unseres Onlineangebotes
                überhaupt ermöglichen zu können). In einem Session-Cookie wird
                eine zufällig erzeugte eindeutige Identifikationsnummer abgelegt,
                eine sogenannte Session-ID. Außerdem enthält ein Cookie die Angabe
                über seine Herkunft und die Speicherfrist. Diese Cookies können
                keine anderen Daten speichern. Session-Cookies werden gelöscht,
                wenn Sie die Nutzung unseres Onlineangebotes beendet haben und
                sich z.B. ausloggen oder den Browser schließen.
              </li>
              <li className="PrivacyPolicy-item">
                8.3. Über den Einsatz von Cookies im Rahmen pseudonymer
                Reichweitenmessung werden die Nutzer im Rahmen dieser
                Datenschutzerklärung informiert.
              </li>
              <li className="PrivacyPolicy-item">
                8.4. Falls die Nutzer nicht möchten, dass Cookies auf ihrem
                Rechner gespeichert werden, werden sie gebeten die entsprechende
                Option in den Systemeinstellungen ihres Browsers zu deaktivieren.
                Gespeicherte Cookies können in den Systemeinstellungen des
                Browsers gelöscht werden. Der Ausschluss von Cookies kann zu
                Funktionseinschränkungen dieses Onlineangebotes führen.
              </li>
              <li className="PrivacyPolicy-item">
                8.5. Sie können dem Einsatz von Cookies, die der
                Reichweitenmessung und Werbezwecken dienen, über die
                Deaktivierungsseite der Netzwerkwerbeinitiative
                (http://optout.networkadvertising.org/) und zusätzlich die
                US-amerikanische Webseite (http://www.aboutads.info/choices) oder
                die europäische Webseite
                (http://www.youronlinechoices.com/uk/your-ad-choices/)
                widersprechen.
              </li>
            </ul>
            <h2>9. Google Analytics</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                9.1. Wir setzen auf Grundlage unserer berechtigten Interessen
                (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem
                Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f.
                DSGVO) Google Analytics, einen Webanalysedienst der Google Inc.
                („Google“) ein. Google verwendet Cookies. Die durch das Cookie
                erzeugten Informationen über Benutzung des Onlineangebotes durch
                die Nutzer werden in der Regel an einen Server von Google in den
                USA übertragen und dort gespeichert.
              </li>
              <li className="PrivacyPolicy-item">
                9.2. Google ist unter dem Privacy-Shield-Abkommen zertifiziert und
                bietet hierdurch eine Garantie, das europäische Datenschutzrecht
                einzuhalten
                (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active).
              </li>
              <li className="PrivacyPolicy-item">
                9.3. Google wird diese Informationen in unserem Auftrag benutzen,
                um die Nutzung unseres Onlineangebotes durch die Nutzer
                auszuwerten, um Reports über die Aktivitäten innerhalb dieses
                Onlineangebotes zusammenzustellen und um weitere, mit der Nutzung
                dieses Onlineangebotes und der Internetnutzung verbundene
                Dienstleistungen, uns gegenüber zu erbringen. Dabei können aus den
                verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt
                werden.
              </li>
              <li className="PrivacyPolicy-item">
                9.4. Wir setzen Google Analytics ein, um die durch innerhalb von
                Werbediensten Googles und seiner Partner geschalteten Anzeigen,
                nur solchen Nutzern anzuzeigen, die auch ein Interesse an unserem
                Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B.
                Interessen an bestimmten Themen oder Produkten, die anhand der
                besuchten Webseiten bestimmt werden) aufweisen, die wir an Google
                übermitteln (sog. „Remarketing-“, bzw.
                „Google-Analytics-Audiences“). Mit Hilfe der Remarketing Audiences
                möchten wir auch sicherstellen, dass unsere Anzeigen dem
                potentiellen Interesse der Nutzer entsprechen und nicht
                belästigend wirken.
              </li>
              <li className="PrivacyPolicy-item">
                9.5. Wir setzen Google Analytics nur mit aktivierter
                IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer
                wird von Google innerhalb von Mitgliedstaaten der Europäischen
                Union oder in anderen Vertragsstaaten des Abkommens über den
                Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird
                die volle IP-Adresse an einen Server von Google in den USA
                übertragen und dort gekürzt.
              </li>
              <li className="PrivacyPolicy-item">
                9.6. Die von dem Browser des Nutzers übermittelte IP-Adresse wird
                nicht mit anderen Daten von Google zusammengeführt. Die Nutzer
                können die Speicherung der Cookies durch eine entsprechende
                Einstellung ihrer Browser-Software verhindern; die Nutzer können
                darüber hinaus die Erfassung der durch das Cookie erzeugten und
                auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google
                sowie die Verarbeitung dieser Daten durch Google verhindern, indem
                sie das unter folgendem Link verfügbare Browser-Plugin
                herunterladen und installieren:
                http://tools.google.com/dlpage/gaoptout?hl=de.
              </li>
              <li className="PrivacyPolicy-item">
                9.7. Weitere Informationen zur Datennutzung durch Google,
                Einstellungs- und Widerspruchsmöglichkeiten erfahren Sie auf den
                Webseiten von Google:
                https://www.google.com/intl/de/policies/privacy/partners
                („Datennutzung durch Google bei Ihrer Nutzung von Websites oder
                Apps unserer Partner“),
                http://www.google.com/policies/technologies/ads („Datennutzung zu
                Werbezwecken“), http://www.google.de/settings/ads („Informationen
                verwalten, die Google verwendet, um Ihnen Werbung einzublenden“).
              </li>
              <li className="PrivacyPolicy-item">
                9.8. Browser Plugin Sie können die Speicherung der Cookies durch
                eine entsprechende Einstellung Ihrer Browser-Software verhindern;
                wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                gegebenenfalls nicht sämtliche Funktionen dieser Website
                vollumfänglich werden nutzen können. Sie können darüber hinaus die
                Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der
                Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie
                die Verarbeitung dieser Daten durch Google verhindern, indem Sie
                das unter dem folgenden Link verfügbare Browser-Plugin
                herunterladen und installieren:
                https://tools.google.com/dlpage/gaoptout?hl=de.
              </li>
            </ul>
            <h2>10. Google-Re/Marketing-Services</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                10.1. Wir nutzen auf Grundlage unserer berechtigten Interessen
                (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem
                Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f.
                DSGVO) die Marketing- und Remarketing-Dienste (kurz
                „Google-Marketing-Services”) der Google Inc., 1600 Amphitheatre
                Parkway, Mountain View, CA 94043, USA, („Google“).
              </li>
              <li className="PrivacyPolicy-item">
                10.2. Google ist unter dem Privacy-Shield-Abkommen zertifiziert
                und bietet hierdurch eine Garantie, das europäische
                Datenschutzrecht einzuhalten
                (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active).
              </li>
              <li className="PrivacyPolicy-item">
                10.3. Die Google-Marketing-Services erlauben uns Werbeanzeigen für
                und auf unserer Website gezielter anzuzeigen, um Nutzern nur
                Anzeigen zu präsentieren, die potentiell deren Interessen
                entsprechen. Falls einem Nutzer z.B. Anzeigen für Produkte
                angezeigt werden, für die er sich auf anderen Webseiten
                interessiert hat, spricht man hierbei vom „Remarketing“. Zu diesen
                Zwecken wird bei Aufruf unserer und anderer Webseiten, auf denen
                Google-Marketing-Services aktiv sind, unmittelbar durch Google ein
                Code von Google ausgeführt und es werden sog. (Re)marketing-Tags
                (unsichtbare Grafiken oder Code, auch als "Web Beacons"
                bezeichnet) in die Webseite eingebunden. Mit deren Hilfe wird auf
                dem Gerät der Nutzer ein individuelles Cookie, d.h. eine kleine
                Datei abgespeichert (statt Cookies können auch vergleichbare
                Technologien verwendet werden). Die Cookies können von
                verschiedenen Domains gesetzt werden, unter anderem von
                google.com, doubleclick.net, invitemedia.com, admeld.com,
                googlesyndication.com oder googleadservices.com. In dieser Datei
                wird vermerkt, welche Webseiten der Nutzer aufgesucht, für welche
                Inhalte er sich interessiert und welche Angebote er geklickt hat,
                ferner technische Informationen zum Browser und Betriebssystem,
                verweisende Webseiten, Besuchszeit sowie weitere Angaben zur
                Nutzung des Onlineangebotes. Es wird ebenfalls die IP-Adresse der
                Nutzer erfasst, wobei wir im Rahmen von Google-Analytics
                mitteilen, dass die IP-Adresse innerhalb von Mitgliedstaaten der
                Europäischen Union oder in anderen Vertragsstaaten des Abkommens
                über den Europäischen Wirtschaftsraum gekürzt und nur in
                Ausnahmefällen ganz an einen Server von Google in den USA
                übertragen und dort gekürzt wird. Die IP-Adresse wird nicht mit
                Daten des Nutzers innerhalb von anderen Angeboten von Google
                zusammengeführt. Die vorstehend genannten Informationen können
                seitens Google auch mit solchen Informationen aus anderen Quellen
                verbunden werden. Wenn der Nutzer anschließend andere Webseiten
                besucht, können ihm entsprechend seiner Interessen die auf ihn
                abgestimmten Anzeigen angezeigt werden.
              </li>
              <li className="PrivacyPolicy-item">
                10.4. Die Daten der Nutzer werden im Rahmen der
                Google-Marketing-Services pseudonym verarbeitet. D.h. Google
                speichert und verarbeitet z.B. nicht den Namen oder E-Mailadresse
                der Nutzer, sondern verarbeitet die relevanten Daten
                Cookie-bezogen innerhalb pseudonymer Nutzer-Profile. D.h. aus der
                Sicht von Google werden die Anzeigen nicht für eine konkret
                identifizierte Person verwaltet und angezeigt, sondern für den
                Cookie-Inhaber, unabhängig davon wer dieser Cookie-Inhaber ist.
                Dies gilt nicht, wenn ein Nutzer Google ausdrücklich erlaubt hat,
                die Daten ohne diese Pseudonymisierung zu verarbeiten. Die von
                Google-Marketing-Services über die Nutzer gesammelten
                Informationen werden an Google übermittelt und auf Googles Servern
                in den USA gespeichert.
              </li>
              <li className="PrivacyPolicy-item">
                10.5. Zu den von uns eingesetzten Google-Marketing-Services gehört
                u.a. das Online-Werbeprogramm „Google AdWords“. Im Fall von Google
                AdWords, erhält jeder AdWords-Kunde ein anderes
                „Conversion-Cookie“. Cookies können somit nicht über die Websites
                von AdWords-Kunden nachverfolgt werden. Die mit Hilfe des Cookies
                eingeholten Informationen dienen dazu, Conversion-Statistiken für
                AdWords-Kunden zu erstellen, die sich für Conversion-Tracking
                entschieden haben. Die AdWords-Kunden erfahren die Gesamtanzahl
                der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit
                einem Conversion-Tracking-Tag versehenen Seite weitergeleitet
                wurden. Sie erhalten jedoch keine Informationen, mit denen sich
                Nutzer persönlich identifizieren lassen.
              </li>
              <li className="PrivacyPolicy-item">
                10.6. Wir können auf Grundlage des Google-Marketing-Services
                "DoubleClick" Werbeanzeigen Dritter einbinden. DoubleClick
                verwendet Cookies, mit denen Google und seinen Partner-Websites,
                die Schaltung von Anzeigen auf Basis der Besuche von Nutzern auf
                dieser Website bzw. anderen Websites im Internet ermöglicht wird.
              </li>
              <li className="PrivacyPolicy-item">
                10.7. Wir können auf Grundlage des Google-Marketing-Services
                „AdSense“ Werbeanzeigen Dritter einbinden. AdSense verwendet
                Cookies, mit denen Google und seinen Partner-Websites, die
                Schaltung von Anzeigen auf Basis der Besuche von Nutzern auf
                dieser Website bzw. anderen Websites im Internet ermöglicht wird.
              </li>
              <li className="PrivacyPolicy-item">
                10.8. Ebenfalls können wir den Dienst „Google Optimizer“
                einsetzen. Google Optimizer erlaubt uns im Rahmen so genannten
                "A/B-Testings" nachzuvollziehen, wie sich verschiedene Änderungen
                einer Website auswirken (z.B. Veränderungen der Eingabefelder, des
                Designs, etc.). Für diese Testzwecke werden Cookies auf den
                Geräten der Nutzer abgelegt. Dabei werden nur pseudonyme Daten der
                Nutzer verarbeitet.
              </li>
              <li className="PrivacyPolicy-item">
                10.9. Ferner können wir den "Google Tag Manager" einsetzen, um die
                Google Analyse- und Marketing-Dienste in unsere Website
                einzubinden und zu verwalten.
              </li>
              <li className="PrivacyPolicy-item">
                10.10. Weitere Informationen zur Datennutzung zu Marketingzwecken
                durch Google, erfahren Sie auf der Übersichtsseite:
                https://www.google.com/policies/technologies/ads, die
                Datenschutzerklärung von Google ist unter
                https://www.google.com/policies/privacy abrufbar.
              </li>
              <li className="PrivacyPolicy-item">
                10.11. Wenn Sie der interessensbezogenen Werbung durch
                Google-Marketing-Services widersprechen möchten, können Sie die
                von Google gestellten Einstellungs- und Opt-Out-Möglichkeiten
                nutzen: http://www.google.com/ads/preferences.
              </li>
            </ul>
            <h2>11. Facebook Social Plugins</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                11.1. Wir nutzen auf Grundlage unserer berechtigten Interessen
                (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem
                Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f.
                DSGVO) Social Plugins ("Plugins") des sozialen Netzwerkes
                facebook.com, welches von der Facebook Ireland Ltd., 4 Grand Canal
                Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird
                ("Facebook"). Die Plugins können Interaktionselemente oder Inhalte
                (z.B. Videos, Grafiken oder Textbeiträge) darstellen und sind an
                einem der Facebook Logos erkennbar (weißes „f“ auf blauer Kachel,
                den Begriffen "Like", "Gefällt mir" oder einem „Daumen
                hoch“-Zeichen) oder sind mit dem Zusatz "Facebook Social Plugin"
                gekennzeichnet. Die Liste und das Aussehen der Facebook Social
                Plugins kann hier eingesehen werden:
                https://developers.facebook.com/docs/plugins/.
              </li>
              <li className="PrivacyPolicy-item">
                11.2. Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert
                und bietet hierdurch eine Garantie, das europäische
                Datenschutzrecht einzuhalten
                (https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active).
              </li>
              <li className="PrivacyPolicy-item">
                11.3. Wenn ein Nutzer eine Funktion dieses Onlineangebotes
                aufruft, die ein solches Plugin enthält, baut sein Gerät eine
                direkte Verbindung mit den Servern von Facebook auf. Der Inhalt
                des Plugins wird von Facebook direkt an das Gerät des Nutzers
                übermittelt und von diesem in das Onlineangebot eingebunden. Dabei
                können aus den verarbeiteten Daten Nutzungsprofile der Nutzer
                erstellt werden. Wir haben daher keinen Einfluss auf den Umfang
                der Daten, die Facebook mit Hilfe dieses Plugins erhebt und
                informiert die Nutzer daher entsprechend unserem Kenntnisstand.
              </li>
              <li className="PrivacyPolicy-item">
                11.4. Durch die Einbindung der Plugins erhält Facebook die
                Information, dass ein Nutzer die entsprechende Seite des
                Onlineangebotes aufgerufen hat. Ist der Nutzer bei Facebook
                eingeloggt, kann Facebook den Besuch seinem Facebook-Konto
                zuordnen. Wenn Nutzer mit den Plugins interagieren, zum Beispiel
                den Like Button betätigen oder einen Kommentar abgeben, wird die
                entsprechende Information von Ihrem Gerät direkt an Facebook
                übermittelt und dort gespeichert. Falls ein Nutzer kein Mitglied
                von Facebook ist, besteht trotzdem die Möglichkeit, dass Facebook
                seine IP-Adresse in Erfahrung bringt und speichert. Laut Facebook
                wird in Deutschland nur eine anonymisierte IP-Adresse gespeichert.
              </li>
              <li className="PrivacyPolicy-item">
                11.5. Zweck und Umfang der Datenerhebung und die weitere
                Verarbeitung und Nutzung der Daten durch Facebook sowie die
                diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz
                der Privatsphäre der Nutzer, können diese den Datenschutzhinweisen
                von Facebook entnehmen: https://www.facebook.com/about/privacy/.
              </li>
              <li className="PrivacyPolicy-item">
                11.6. Wenn ein Nutzer Facebookmitglied ist und nicht möchte, dass
                Facebook über dieses Onlineangebot Daten über ihn sammelt und mit
                seinen bei Facebook gespeicherten Mitgliedsdaten verknüpft, muss
                er sich vor der Nutzung unseres Onlineangebotes bei Facebook
                ausloggen und seine Cookies löschen. Weitere Einstellungen und
                Widersprüche zur Nutzung von Daten für Werbezwecke, sind innerhalb
                der Facebook-Profileinstellungen möglich:
                https://www.facebook.com/settings?tab=ads oder über die
                US-amerikanische Seite http://www.aboutads.info/choices/ oder die
                EU-Seite http://www.youronlinechoices.com/. Die Einstellungen
                erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie
                Desktopcomputer oder mobile Geräte übernommen.
              </li>
            </ul>
            <h2>
              12. Facebook-, Custom Audiences und Facebook-Marketing-Dienste
            </h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                12.1. Innerhalb unseres Onlineangebotes wird aufgrund unserer
                berechtigten Interessen an Analyse, Optimierung und
                wirtschaftlichem Betrieb unseres Onlineangebotes und zu diesen
                Zwecken das sog. "Facebook-Pixel" des sozialen Netzwerkes
                Facebook, welches von der Facebook Inc., 1 Hacker Way, Menlo Park,
                CA 94025, USA, bzw. falls Sie in der EU ansässig sind, Facebook
                Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
                Irland betrieben wird ("Facebook"), eingesetzt.
              </li>
              <li className="PrivacyPolicy-item">
                12.2. Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert
                und bietet hierdurch eine Garantie, das europäische
                Datenschutzrecht einzuhalten
                (https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active).
              </li>
              <li className="PrivacyPolicy-item">
                12.3. Mit Hilfe des Facebook-Pixels ist es Facebook zum einen
                möglich, die Besucher unseres Onlineangebotes als Zielgruppe für
                die Darstellung von Anzeigen (sog. "Facebook-Ads") zu bestimmen.
                Dementsprechend setzen wir das Facebook-Pixel ein, um die durch
                uns geschalteten Facebook-Ads nur solchen Facebook-Nutzern
                anzuzeigen, die auch ein Interesse an unserem Onlineangebot
                gezeigt haben oder die bestimmte Merkmale (z.B. Interessen an
                bestimmten Themen oder Produkten, die anhand der besuchten
                Webseiten bestimmt werden) aufweisen, die wir an Facebook
                übermitteln (sog. „Custom Audiences“). Mit Hilfe des
                Facebook-Pixels möchten wir auch sicherstellen, dass unsere
                Facebook-Ads dem potentiellen Interesse der Nutzer entsprechen und
                nicht belästigend wirken. Mit Hilfe des Facebook-Pixels können wir
                ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische
                und Marktforschungszwecke nachvollziehen, in dem wir sehen ob
                Nutzer nachdem Klick auf eine Facebook-Werbeanzeige auf unsere
                Website weitergeleitet wurden (sog. „Conversion“).
              </li>
              <li className="PrivacyPolicy-item">
                12.4. Das Facebook-Pixel wird beim Aufruf unserer Webseiten
                unmittelbar durch Facebook eingebunden und kann auf Ihrem Gerät
                ein sog. Cookie, d.h. eine kleine Datei abspeichern. Wenn Sie sich
                anschließend bei Facebook einloggen oder im eingeloggten Zustand
                Facebook besuchen, wird der Besuch unseres Onlineangebotes in
                Ihrem Profil vermerkt. Die über Sie erhobenen Daten sind für uns
                anonym, bieten uns also keine Rückschlüsse auf die Identität der
                Nutzer. Allerdings werden die Daten von Facebook gespeichert und
                verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil
                möglich ist und von Facebook sowie zu eigenen Marktforschungs- und
                Werbezwecken verwendet werden kann. Sofern wir Daten zu
                Abgleichzwecken an Facebook übermitteln sollten, werden diese
                lokal in auf dem Browser verschlüsselt und erst dann an Facebook
                über eine gesicherte https-Verbindung gesendet. Dies erfolgt
                alleine mit dem Zweck, einen Abgleich mit den gleichermaßen durch
                Facebook verschlüsselten Daten herzustellen.
              </li>
              <li className="PrivacyPolicy-item">
                12.5. Des Weiteren nutzen wir beim Einsatz des Facebook-Pixels die
                Zusatzfunktion „erweiterter Abgleich“ (hierbei werden Daten wie
                Telefonnummern, E-Mailadressen oder Facebook-IDs der Nutzer) zur
                Bildung von Zielgruppen („Custom Audiences“ oder „Look Alike
                Audiences“) an Facebook (verschlüsselt) übermittelt. Weitere
                Hinweise zum „erweiterten Abgleich“:
                https://www.facebook.com/business/help/611774685654668).
              </li>
              <li className="PrivacyPolicy-item">
                12.6. Ebenfalls auf der Grundlage unserer berechtigten Interessen
                nutzen wir das Verfahren „Custom Audiences from File“ des sozialen
                Netzwerks Facebook, Inc. In diesem Fall werden die E-Mail-Adressen
                der Newsletterempfänger bei Facebook hochgeladen. Der
                Upload-Vorgang findet verschlüsselt statt. Der Upload dient
                alleine, um Empfänger unserer Facebook-Anzeigen zu bestimmen. Wir
                möchten damit sicherstellen, dass die Anzeigen nur Nutzern
                angezeigt werden, die ein Interesse an unseren Informationen und
                Leistungen haben.
              </li>
              <li className="PrivacyPolicy-item">
                12.7. Die Verarbeitung der Daten durch Facebook erfolgt im Rahmen
                von Facebooks Datenverwendungsrichtlinie. Dementsprechend
                generelle Hinweise zur Darstellung von Facebook-Ads, in der
                Datenverwendungsrichtlinie von Facebook:
                https://www.facebook.com/policy.php. Spezielle Informationen und
                Details zum Facebook-Pixel und seiner Funktionsweise erhalten Sie
                im Hilfebereich von Facebook:
                https://www.facebook.com/business/help/651294705016616.
              </li>
              <li className="PrivacyPolicy-item">
                12.8. Sie können der Erfassung durch den Facebook-Pixel und
                Verwendung Ihrer Daten zur Darstellung von Facebook-Ads
                widersprechen. Um einzustellen, welche Arten von Werbeanzeigen
                Ihnen innerhalb von Facebook angezeigt werden, können Sie die von
                Facebook eingerichtete Seite aufrufen und dort die Hinweise zu den
                Einstellungen nutzungsbasierter Werbung befolgen:
                https://www.facebook.com/settings?tab=ads. Die Einstellungen
                erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie
                Desktopcomputer oder mobile Geräte übernommen.
              </li>
              <li className="PrivacyPolicy-item">
                12.9. Sie können dem Einsatz von Cookies, die der
                Reichweitenmessung und Werbezwecken dienen, ferner über die
                Deaktivierungsseite der Netzwerkwerbeinitiative
                (http://optout.networkadvertising.org/) und zusätzlich die
                US-amerikanische Webseite (http://www.aboutads.info/choices) oder
                die europäische Webseite
                (http://www.youronlinechoices.com/uk/your-ad-choices/)
                widersprechen.
              </li>
            </ul>
            <h2>13. Einbindung von Diensten und Inhalten Dritter</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                13.1. Wir setzen innerhalb unseres Onlineangebotes auf Grundlage
                unserer berechtigten Interessen (d.h. Interesse an der Analyse,
                Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes
                im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder
                Serviceangebote von Drittanbietern ein, um deren Inhalte und
                Services, wie z.B. Videos oder Schriftarten einzubinden
                (nachfolgend einheitlich bezeichnet als “Inhalte”). Dies setzt
                immer voraus, dass die Drittanbieter dieser Inhalte, die
                IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die
                Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist
                damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen
                uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die
                IP-Adresse lediglich zur Auslieferung der Inhalte verwenden.
                Drittanbieter können ferner so genannte Pixel-Tags (unsichtbare
                Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder
                Marketingzwecke verwenden. Durch die "Pixel-Tags" können
                Informationen, wie der Besucherverkehr auf den Seiten dieser
                Website ausgewertet werden. Die pseudonymen Informationen können
                ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und
                unter anderem technische Informationen zum Browser und
                Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere
                Angaben zur Nutzung unseres Onlineangebotes enthalten, als auch
                mit solchen Informationen aus anderen Quellen verbunden werden
                können.
              </li>
              <li className="PrivacyPolicy-item">
                13.2. Die nachfolgende Darstellung bietet eine Übersicht von
                Drittanbietern sowie ihrer Inhalte, nebst Links zu deren
                Datenschutzerklärungen, welche weitere Hinweise zur Verarbeitung
                von Daten und, z.T. bereits hier genannt,
                Widerspruchsmöglichkeiten (sog. Opt-Out) enthalten: - Webanalyse
                und Optimierung mit Hilfe des Dienstes Hotjar, des Drittanbieters
                Hotjar Ltd., Level 2, St Julians Business Centre, 3, Elia Zammit
                Street, St Julians STJ 1000, Malta, Europe. Mit Hotjar lassen sich
                Bewegungen auf den Webseiten, auf denen Hotjar eingesetzt wird,
                nachvollziehen (sog. Heatmaps). So ist beispielsweise erkennbar,
                wie weit Nutzer scrollen und welche Schaltflächen die Nutzer wie
                oft anklicken. Ferner werden technische Daten wie gewählte
                Sprache, System, Bildschirmauflösung und Browserart erfasst.
                Hierbei können, zumindest vorübergehend während des Besuchs auf
                unserer Webseite Profile der Nutzer erstellt werden. Weiterhin ist
                es mithilfe von Hotjar auch möglich, Feedback direkt von den
                Nutzern der Website einzuholen. Auf diese Weise erlangen wir
                wertvolle Informationen, um unsere Websites noch schneller und
                kundenfreundlicher zu gestalten. Datenschutzerklärung:
                https://www.hotjar.com/privacy. Opt-Out:
                https://www.hotjar.com/opt-out. - Externer Code des
                JavaScript-Frameworks “jQuery”, bereitgestellt durch den
                Dritt-Anbieter jQuery Foundation, https://jquery.org.
              </li>
            </ul>
            <h2>14. Rechte der Nutzer</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                14.1. Nutzer haben das Recht, auf Antrag unentgeltlich Auskunft zu
                erhalten über die personenbezogenen Daten, die von uns über sie
                gespeichert wurden.
              </li>
              <li className="PrivacyPolicy-item">
                14.2. Zusätzlich haben die Nutzer das Recht auf Berichtigung
                unrichtiger Daten, Einschränkung der Verarbeitung und Löschung
                ihrer personenbezogenen Daten, sofern zutreffend, Ihre Rechte auf
                Datenportabilität geltend zu machen und im Fall der Annahme einer
                unrechtmäßigen Datenverarbeitung, eine Beschwerde bei der
                zuständigen Aufsichtsbehörde einzureichen.
              </li>
              <li className="PrivacyPolicy-item">
                14.3. Ebenso können Nutzer Einwilligungen, grundsätzlich mit
                Auswirkung für die Zukunft, widerrufen.
              </li>
            </ul>
            <h2>15. Löschung von Daten</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                15.1. Die bei uns gespeicherten Daten werden gelöscht, sobald sie
                für ihre Zweckbestimmung nicht mehr erforderlich sind und der
                Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
                Sofern die Daten der Nutzer nicht gelöscht werden, weil sie für
                andere und gesetzlich zulässige Zwecke erforderlich sind, wird
                deren Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt
                und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten
                der Nutzer, die aus handels- oder steuerrechtlichen Gründen
                aufbewahrt werden müssen.
              </li>
              <li className="PrivacyPolicy-item">
                15.2. Nach gesetzlichen Vorgaben erfolgt die Aufbewahrung für 6
                Jahre gemäß § 257 Abs. 1 HGB (Handelsbücher, Inventare,
                Eröffnungsbilanzen, Jahresabschlüsse, Handelsbriefe,
                Buchungsbelege, etc.) sowie für 10 Jahre gemäß § 147 Abs. 1 AO
                (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege, Handels-
                und Geschäftsbriefe, Für Besteuerung relevante Unterlagen, etc.).
              </li>
            </ul>
            <h2>16. Widerspruchsrecht</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                16.1. Nutzer können der künftigen Verarbeitung ihrer
                personenbezogenen Daten entsprechend den gesetzlichen Vorgaben
                jederzeit widersprechen. Der Widerspruch kann insbesondere gegen
                die Verarbeitung für Zwecke der Direktwerbung erfolgen.
              </li>
            </ul>
            <h2>17. Ansprechpartner</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                17.1. Wegen eventueller weiterer Auskünfte und Erläuterungen oder
                einem etwaigen Verlangen auf Auskunft, Berichtigung, Sperrung oder
                Löschung gespeicherten Daten wenden Sie sich bitte an:
              </li>
              <li className="PrivacyPolicy-item">
                Wegen eventueller weiterer Auskünfte und Erläuterungen oder einem
                etwaigen Verlangen auf Auskunft, Berichtigung, Sperrung oder
                Löschung gespeicherten Daten wenden Sie sich bitte an:
              </li>
              <li className="PrivacyPolicy-item">Datenschutzbeauftragter:</li>
              <li className="PrivacyPolicy-item">Name: Jens Langer</li>
              <li className="PrivacyPolicy-item">
                Straße Nr.: An der Trave 24
              </li>
              <li className="PrivacyPolicy-item">
                PLZ, Ort, Land: 23923 Selmsdorf
              </li>
              <li className="PrivacyPolicy-item">
                E-Mail adresse: industriemontage-langerl(@-zeichen)t-online.de
              </li>
            </ul>
            <h2>18. Hinweis zu verantwortlichen Stelle</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                18.1. Die verantwortliche Stelle für die Datenverarbeitung auf
                dieser Website ist: Industriemontage Langer GmbH, An der Trave 24
                23923 Selmsdorf, E-Mail: industriemontage-langerl(@-zeichen)t-online.de . Verantwortliche
                Stelle ist die natürliche oder juristische Person, die allein oder
                gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
                von personenbezogenen Daten (z.B. Namen, E-Mail- Adressen o. Ä.)
                entscheidet.
              </li>
            </ul>
            <h2>19. Änderungen der Datenschutzerklärung</h2>
            <ul className="PrivacyPolicy-list">
              <li className="PrivacyPolicy-item">
                19.1. Wir behalten uns vor, die Datenschutzerklärung zu ändern, um
                sie an geänderte Rechtslagen, oder bei Änderungen des Dienstes
                sowie der Datenverarbeitung anzupassen. Dies gilt jedoch nur im
                Hinblick auf Erklärungen zur Datenverarbeitung. Sofern
                Einwilligungen der Nutzer erforderlich sind oder Bestandteile der
                Datenschutzerklärung Regelungen des Vertragsverhältnisses mit den
                Nutzern enthalten, erfolgen die Änderungen nur mit Zustimmung der
                Nutzer.
              </li>
              <li className="PrivacyPolicy-item">
                19.2. Die Nutzer werden gebeten sich regelmäßig über den Inhalt
                der Datenschutzerklärung zu informieren.
              </li>
              <li className="PrivacyPolicy-item">
                .
              </li>
              <li className="PrivacyPolicy-item">
                .
              </li>
              <li className="PrivacyPolicy-item">
                .
              </li>
              <li className="PrivacyPolicy-item">
                .
              </li>
              
            </ul>
          </main>
        </article>
      </Grid>
      </Grid>
    );
  }