
import './App.css';
import MainContainer from './components/MainContainer.js';

function App() {
  return (
    <div className="App">
      <MainContainer/>
    </div>
  );
}

export default App;
